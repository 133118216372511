.list-tile {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.with-separator {
    border-bottom: 1px solid var(--outline);
    // Include some bottom padding even if no-padding is set, otherwise
    // separator will be too close
    &.no-padding {
      padding-bottom: 8px;
    }
  }

  .leading {
    margin-right: 12px;
  }
  .inner {
    display: flex;
    min-width: 0;
    flex: 1;
    flex-direction: column;
    .title {
      padding-right: 4px;
    }
    .subtitle {
      margin-top: 2px;
    }
  }
  &:not(.allow-wrap) {
    .title,
    .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &:not(.no-padding) {
    padding: 16px 0;
  }
}

div.group {
  padding: 0;

  &.outlined {
    > :not(:last-child) {
      box-shadow: 0 1px 0 0 var(--outline);
    }
    .list-tile {
      padding: 16px 24px;
    }
  }
}
