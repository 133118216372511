.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

$prefixes: "";
