.collapsible {
  overflow: hidden;
  padding: 1px;
  // Show 1px outline for content
  margin: -1px;

  transition: height 0.3s;

  .wrapper {
    overflow: hidden;
    padding: 1px;
    margin: -1px;
    transition: opacity 0.3s;
  }
}
