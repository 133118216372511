.trailing {
  display: flex;
  min-width: 65px;
  align-items: center;
  justify-content: center;
}

.inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;

  .title-wrapper {
    color: #081131;
  }
  .subtitle {
    text-align: right;
  }
}

.arrow-link {
  display: flex;
  align-items: center;
  line-height: 1;

  .arrow {
    margin-left: 4px;
    transition: transform 0.3s;
  }
}
