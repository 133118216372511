@import "@kikoff/client-utils/src/stylesheets/constants/stacking-context.scss";

.content-container {
  z-index: z(tooltip);

  margin-right: 8px;
}
.content {
  max-width: 300px;
  box-sizing: border-box;
  padding: 12px;

  background: black;
  border-radius: 4px;
  color: white;
}
