.messages {
  position: relative;
  overflow: hidden;

  .group {
    display: flex;
    align-items: flex-end;
    // 6px here instead of 8 since 2px is already included in each message
    padding: 6px 0;
    animation: grow 0.3s;

    @keyframes grow {
      from {
        padding: 0;
      }
    }

    .avatar {
      overflow: hidden;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      margin-right: 8px;

      animation: growfade-in 0.3s 0.1s backwards;
      border-radius: 50%;

      @keyframes growfade-in {
        from {
          height: 0;
          opacity: 0;
        }
      }

      svg {
        display: block;
        width: 32px;
      }
    }
    .group-messages {
      flex: 1;
    }
  }
}

.message {
  position: relative;

  .bubble {
    overflow: hidden;
    width: fit-content;
    max-width: 90%;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 18px;
    line-height: 20px;
    transition: border-radius 0.3s;
    word-wrap: break-word;

    .content {
      width: fit-content;
    }

    &.measure {
      position: absolute;
    }
  }

  &.sent .bubble {
    margin-left: auto;
    background: var(--message);
    color: var(--on-message);

    &.last {
      border-bottom-right-radius: 4px;
    }
  }
  &.received .bubble {
    background: var(--outline);

    &.last {
      border-bottom-left-radius: 4px;
    }
  }
}

.loading-indicator {
  display: flex;
  height: 20px;
  align-items: center;
  margin: 0 4px;

  > div {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    margin-right: 6px;

    animation: hop 1.5s infinite;
    background: var(--primary);
    border-radius: 50%;
    @keyframes hop {
      0% {
        transform: none;
      }
      20% {
        transform: translateY(-4px);
      }
      40% {
        transform: none;
      }
    }

    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      margin-right: 0;
      animation-delay: 0.2s;
    }
  }
}
