@use "./typography.scss";
@import "./legacy.scss";

@font-face {
  font-family: "NoiGrotesk";
  font-weight: 200;
  src: url("https://kikoff-public-assets.s3.amazonaws.com/public/fonts/NoiGroteskKikoff-UltraLight.woff2");
}

@font-face {
  font-family: "NoiGrotesk";
  font-weight: 300;
  src: url("https://kikoff-public-assets.s3.amazonaws.com/public/fonts/	NoiGroteskKikoff-Light.woff2");
}

@font-face {
  font-family: "NoiGrotesk";
  font-weight: 400;
  src: url("https://kikoff-public-assets.s3.amazonaws.com/public/fonts/NoiGroteskKikoff-Regular.woff2");
}

@font-face {
  font-family: "NoiGrotesk";
  font-weight: 500;
  src: url("https://kikoff-public-assets.s3.amazonaws.com/public/fonts/NoiGroteskKikoff-Medium.woff2");
}

@font-face {
  font-family: "NoiGrotesk";
  font-weight: 700;
  src: url("https://kikoff-public-assets.s3.amazonaws.com/public/fonts/NoiGroteskKikoff-Bold.woff2");
}

@font-face {
  font-family: "KikoffIcons";
  src: url("https://kikoff-public-assets.s3.amazonaws.com/public/fonts/KikoffIcons.woff2");
}

html,
body {
  color: var(--strong);

  font-family: "NoiGrotesk", sans-serif, "KikoffIcons";
  font-feature-settings: "ss01", "ss03";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Regular
  letter-spacing: 0.4px;
  line-height: 20px;
}

$prefixes: "text:";

.text\: {
  @each $key, $properties in module-variables(typography) {
    &#{$key} {
      @each $property, $value in $properties {
        #{$property}: #{$value} !important;
      }
    }
    &#{$key}\+ {
      @extend .text\:#{$key};
      font-weight: 500 !important;
    }
    &#{$key}\+\+ {
      @extend .text\:#{$key};
      font-weight: 700 !important;
    }
  }
  &\+ {
    font-weight: 500 !important;
  }
  &\+\+ {
    font-weight: 700 !important;
  }
}

h1 {
  @extend .text\:heading-1;
}
h2 {
  @extend .text\:heading-2;
}
h3 {
  @extend .text\:heading-3;
}
h4 {
  @extend .text\:heading-4;
}
h5 {
  @extend .text\:large\+;
}
h1,
h2,
h3,
h4,
h5 {
  margin-block: 1em;
}
