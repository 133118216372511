@use './index.scss' as light-colors;
@use './dark.scss' as dark-colors;

$prefixes: "color: bg: color-base:";

.color\: {
  &inherit {
    color: inherit;
  }

  @each $key, $value in module-variables(light-colors) {
    &#{$key} {
      color: var(--#{$key}) !important;
    }
  }
}

.bg\: {
  @each $key, $value in module-variables(light-colors) {
    &#{$key} {
      background: var(--#{$key}) !important;
    }
  }
}

.color-base\:dugout {
  --strong: var(--strong-on-dugout);
  --moderate: var(--moderate-on-dugout);
  --moderate-weak: var(--moderate-on-dugout);
  --weak: var(--weak-on-dugout);
  --container: var(--dugout);
}

.color-base\:primary-strong {
  --strong: var(--on-primary-strong);
  --moderate: var(--on-primary-strong);
  --moderate-weak: var(--on-primary-strong);
  --weak: var(--on-primary-strong);
  --container: var(--primary-strong);
}

@function rgb-vals($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root,
html[data-theme="light"] {
  transition: 0.5s;
  @each $key, $value in module-variables(light-colors) {
    --#{$key}: #{$value};
    --#{$key}-rgb: #{rgb-vals($value)};
  }
}

html[data-theme="dark"] {
  @each $key, $value in module-variables(dark-colors) {
    --#{$key}: #{$value};
    --#{$key}-rgb: #{rgb-vals($value)};
  }
}

@media (prefers-color-scheme: dark) {
  html[data-theme="system"] {
    @each $key, $value in module-variables(dark-colors) {
      --#{$key}: #{$value};
      --#{$key}-rgb: #{rgb-vals($value)};
    }
  }
}
